<template>
	<div>
		<Header style="display:inline-block;"></Header>
		<!--报名人员列表-->
		<div class="main-contain">
			<div class="mian">
				<ul class="item-list">
					<li v-for="item in list" :key="item.rowNumber">
						<div class="item">
							<div class="">{{item.zwmc}}</div>
							<div class="">
								<img v-if="item.peR_IMG_PATH!=''" :src="item.peR_IMG_PATH" alt="" />
								<img v-else-if="item.peR_GENDER=='男'" :src="require(`@/assets/img/male_img.png`)" alt="" />
								<img v-else :src="require(`@/assets/img/nv.png`)" alt="" />
							</div>
							<div class="">{{item.peR_NAME}}</div>
							<div class="">{{item.peR_WORK_YEARS}}年以上经验</div>
							<a @click="goto('/jobfairUserdetail', { id: item.peR_ID })" class="goto">
								查看简历
							</a>
						</div>
					</li>
				</ul>
				<div></div>

				<div style="text-align: center; margin-top: 10px">
					<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
						:current-page="selfrom.pageNumber" :page-sizes="[20, 40, 80, 120]" :page-size="selfrom.pageSize"
						layout="total, sizes, prev, pager, next, jumper" :total="total">
					</el-pagination>
				</div>
			</div>
		</div>
		<Footer></Footer>
	</div>
</template>

<script>
	import {
		getzphperjobbycomid,
	} from "@/api/company";
	import utils from "@/assets/js/utils";
	import {
		isnull
	} from "@/assets/js/index";
	import global from "@/assets/js/globalconfig";
	import CuPager from '../../components/CuPager.vue';
	import Header from "@/components/header3.vue";
	import Footer from "@/components/footer1.vue";
	export default {
		components: {
			Header,
			Footer,
			CuPager
		},
		data() {
			return {
				id: 0,
				selfrom: {
					pageNumber: 1,
					pageSize: 20,
					searchKey: "",
				},
				total: 0,
				searchKey: '',
				list: []
			}
		},
		created() {
			this.id = this.$route.query.id;
			this.getlist() 
		},
		methods: {
			goto(url, data) {
				this.resolvewin(url, data, 1);
			},
			handleSizeChange(val) {
				this.selfrom.pageSize = val;
				this.selfrom.pageNumber = 1;
				this.getlist();
			},
			handleCurrentChange(val) {
				this.selfrom.pageNumber = val;
				this.getlist();
			},
			getlist() {
				var _this = this;
				var par = {
					id: this.id,
					PageSize: _this.PageSize,
					PageNumber: _this.PageNumber,
					searchKey: _this.searchKey,
					showLoadType: ".resumelist",
				};
				getzphperjobbycomid(par).then((res) => {
					if (res.success) {
						_this.total = res.data.total;
						_this.list = res.data.rows;
					} else {
						_this.$message.error(res.msg);
					}
				});
			}
		}
	}
</script>

<style lang="scss" scoped>
	.main-contain {
		height: calc(100vh - 100px);
	}

	.mian {
		width: 1200px;
		margin: 20px auto;
	}

	.item-list {
		overflow: hidden;
		list-style-type: none;

		li {
			width: 20%;
			float: left;
			text-align: center;
			font-size: 14px;

			.item {
				margin: 10px;
				padding: 10px;
				background-color: #fff;
				line-height: 2;

				img {
					width: 60px;
					height: 60px;
					border-radius: 50%;
				}

				.goto {
					background-color: #5087EC;
					color: #fff;
					padding: 0 15px;
					border-radius: 4px;
				}
			}
		}
	}
</style>
